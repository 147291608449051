const billRouter = {
  route: null,
  name: null,
  title: '账单/发票管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'Money',
  isElementIcon: true,
  filePath: 'view/bill/', // 文件路径
  order: null,
  inNav: true,
  children: [
    {
      title: '账单查询',
      type: 'view',
      name: 'CustomerPayroll',
      route: '/bill/list',
      filePath: 'view/bill/bill-list.vue',
      inNav: true,
      icon: '',
    },
    {
      title: '账单详情',
      type: 'view',
      name: 'CustomerPayroll',
      route: '/bill/detail',
      filePath: 'view/bill/bill.vue',
      inNav: false,
      icon: '',
    },
    {
      title: '创建账单',
      type: 'view',
      name: 'BillAdd',
      route: '/bill/add',
      filePath: 'view/bill/bill-add.vue',
      inNav: false,
      icon: '',
    },
    {
      title: '发票管理',
      type: 'view',
      name: 'InvoiceList',
      route: '/invoice/list',
      filePath: 'view/invoice/invoice-list.vue',
      inNav: true,
      icon: '',
    },
    {
      title: '账单设置',
      type: 'view',
      name: 'CustomerPayroll',
      route: '/bill/config',
      filePath: 'view/bill/bill-config-list.vue',
      inNav: true,
      icon: '',
    },

  ],
}

export default billRouter
